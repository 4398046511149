// The hero image can be added to the src folder with the HeroImage.png name.
// Logo image can be added to the src folder as Logo.png
// Favicon image can be added to the public folder as favicon.ico

// Project specific values that can be changed easily.
export const projectConfiguration = {
  // Product Key
  productKey: "shittymorph",

  // Content variables
  heroLineOne: "Talk Smack,",
  heroLineTwo: "Laugh Back:",
  heroLineThree: "AI Has Never Been This Witty",
  subtitle:
    "Engage, tease, jibe - in a fun, interactive insult exchange. Sharpen your banter and challenge your wit against the ultimate insult bot.",

  // Colors
  backgroundColor: "#FE3876",
  primaryTextColor: "#ffffff",
  secondaryTextColor: "#ffffff",
  tertiaryTextColor: "#ffffff",
  inputBackgroundColor: "#ffffff",
  inputFocusBackgroundColor: "#ffffff",
  inputTextColor: "#000000",
  inputFocusTextColor: "#000000",
  punchColor: "#000000", // Used as link color
  footerLinkColor: "#ffffff",
  buttonTextColor: "#ffffff",

  //   Fonts
  primaryFont: "Poppins",
  secondaryFont: "Inter",
};
